import React, { useState, useContext } from "react";
import { WizardContext } from "./Wizard";
import PropTypes from "prop-types";
import _ from "lodash";
import { Form, Input, Button } from "semantic-ui-react";
import { payPlans } from "../BusinessBox/PaypalBusinessPlans";

const FindPromoCodeContent = ({ setFree, setSelectedPlan }) => {
  const sharedValues = useContext(WizardContext);
  const [proSuccMsg, setProSuccMsg] = useState("");
  const [proErr, setProErr] = useState("");
  const [code, setCode] = useState("");

  const checkPPPromoCode = () => {
    if (sharedValues.plan == 6) {
      payPlans.map((pp) => {
        if (pp.plan === sharedValues.plan) {
          if (code === pp.discountCode) {
            setSelectedPlan(pp.discountedPlanID);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          } else if (code === pp.discountCode1) {
            setSelectedPlan(pp.discountedPlanID1);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          } else if (code === pp.discountCode2) {
            setSelectedPlan(pp.discountedPlanID2);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          }
          else if (code === pp.freeCode) {
            setFree(true);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          } else {
            setSelectedPlan(pp.planID);
            setProErr("Wrong Promo Code");
            setProSuccMsg("");
          }
        }
      });
    } else if (sharedValues.plan == 7) {
      payPlans.map((pp) => {
        if (pp.plan === sharedValues.plan) {
          if (code === pp.discountCode) {
            setSelectedPlan(pp.discountedPlanID);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          } else if (code === pp.discountCode1) {
            setSelectedPlan(pp.discountedPlanID1);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          } else if (code === pp.discountCode2) {
            setSelectedPlan(pp.discountedPlanID2);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          }else if (code === pp.discountCode3) {
            setSelectedPlan(pp.discountedPlanID3);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          }else if (code === pp.freeCode) {
            setFree(true);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          } else {
            setSelectedPlan(pp.planID);
            setProErr("Wrong Promo Code");
            setProSuccMsg("");
          }
        }
      });
    } else if (sharedValues.plan == 8) {
      payPlans.map((pp) => {
        if (pp.plan === sharedValues.plan) {
          if (code === pp.discountCode) {
            setSelectedPlan(pp.discountedPlanID);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          } else if (code === pp.freeCode) {
            setFree(true);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          } else {
            setSelectedPlan(pp.planID);
            setProErr("Wrong Promo Code");
            setProSuccMsg("");
          }
        }
      });
    } else if (sharedValues.plan == 9) {
      payPlans.map((pp) => {
        if (pp.plan === sharedValues.plan) {
          if (code === pp.discountCode) {
            setSelectedPlan(pp.discountedPlanID);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          } else if (code === pp.discountCode1) {
            setSelectedPlan(pp.discountedPlanID1);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          } else if (code === pp.freeCode) {
            setFree(true);
            setProErr("");
            setProSuccMsg("Promo Code Applied");
          } else {
            setSelectedPlan(pp.planID);
            setProErr("Wrong Promo Code");
            setProSuccMsg("");
          }
        }
      });
    }
  };
  return (
    <Form.Field>
      <label htmlFor="promocode">Enter Promo Code</label>
      <Input placeholder="Enter Promo Code..." id="promocode" action>
        <input onChange={(event) => setCode(event.target.value)} value={code} />
        <Button
          type="button"
          color="blue"
          onClick={checkPPPromoCode}
          content="Apply Code"
          disabled={!code}
          style={{ fontSize: 14 }}
        />
      </Input>
      {proErr && <span className="error">{proErr}</span>}
      {proSuccMsg && <span className="success">{proSuccMsg}</span>}
    </Form.Field>
  );
};

const FindPromoCode = ({ containerized, ...props }) => {
  if (containerized) {
    return (
      <div className="containerized">
        <FindPromoCodeContent {...props} />
      </div>
    );
  } else {
    return <FindPromoCodeContent {...props} />;
  }
};

FindPromoCode.propTypes = {
  onComplete: PropTypes.func,
  onError: PropTypes.func,
  containerized: PropTypes.bool,
};

FindPromoCode.defaultProps = {
  onComplete: () => {},
  onError: () => {},
  containerized: false,
};

export default FindPromoCode;
