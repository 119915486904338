import React from 'react';

export const HomePageText = {
	1: 'Access hundreds of offers and savings locally and nationally through our extensive Loyalty Network. As a P4P member, you have access to unlimited savings across thousands of affiliates in your local area and across the nation. And we are growing bigger by the day! From dental and chiropractic to family fun and travel, we have those and everything in between!',
	2: 'We have come together to make our local and focal communities strong again.  We are doing something that has never been done where we the people are standing behind our businesses, ensuring they stay in business.  Securing the supply chain, the local economy, increasing communication, stopping division, and ensuring we have a community to rely on.  If available, your business can have exclusive listing for your profession on our website along with podcasts, networking events, workshops, social media referrals, google ratings, and so much more all tailored around you!',
	3: 'If you would like to make a difference in your own community by promoting local businesses and/or help other communities across this nation flourish, then you can sign up to be an educator for free. You will have the opportunity to increase your income while changing the world around us. You can choose how many hours you will work as an educator. By joining in this effort, you will be making our communities and ourselves recession-proof.',
	4: 'Do something good on social media and help our local economy. Businesses may need to let the community know that they are still open, they may now deliver, or have better deals than you can buy online. If you have over 300 followers on your account, then join us.  Support our businesses and get paid on average $50 a post. Share the message of hope and become #people4people.'
};

export const AffiliatesPageText = {
	1: 'We have started this program with you in mind to solve as many problems as we can at this time. We know that as we come together we can make a huge difference in our community and take control of our own future. P4P is structured uniquely so that the people, consumers and businesses are in charge of their own network. In doing this we believe we can be more successful than ever! Please select the program below that can best help you today. We are #people4people! ',
	2: 'Thank you for your interest in helping our communities become stronger. With your assistance we will be able to strengthen America’s economy. You will share our message on social media, Zoom and in your everyday conversations with friends and businesses, trying to help 4 other people or businesses in your community. With these small steps, you will have the opportunity to substantially improve your community and your income. Please contact us so we can start you on this exhilarating journey. We are #people4people!',
	3: 'As a P4P Service Member, you’ll have the opportunity to make a difference in your community. Whether it’s leading a networking event, posting on social media, organizing an event, teaching a workshop, or more—the possibilities are endless, and so is the potential income!  As a Service Member, you’ll receive a discounted membership, be recognized as a leader in your community, and get paid for your contributions. Most service opportunities require just a few hours a month.  Sign up now to unlock all the opportunities available to you as we change the world together with People-4-People!.',
	4: 'We have discovered the top programs to help your business expand and stay recession-proof. We have implemented all these at the lowest cost with the greatest outcome. Nobody is doing what we are doing.  We as communities and local business owners are coming together as a community to help each other. Our network can provide local social media advertising giving you word of mouth advertising people referring to you where you can have exclusivity for your profession. We also offer networking events where you can have community presentations.   There are benefits for you and your employees and then our sharing in the profits program to help your business battle the storms ahead and so much more!',
	5: 'You can list your business for free on our website with your P4P promotion. We are here as a community to help get your message out and help our local economy grow. Start sharing your promotions with our members today and see your clientele increase. We provide advertising and exposure to a large network of hundreds of affiliates that promote your business, and thousands of people that could become your clients. Our educators will be on the ground promoting your business. As we grow so do you! You can change or update your listing each year and you can cancel at any time.  There is never any charge to be a part of this program!',
	6: 'Are you a fan of P4P and the amazing savings and offers it brings to others? Would you like to be paid for sharing your love of P4P and be compensated for anyone who becomes a member from your referral? Become a friend and let us pay you to share what you love about P4P memberships. There will never be minimum requirements for you to meet. We just pay you when a new member joins because you told them about us! Who else pays you to tell people about what you love?'
};

export const PartneredBusinessText = {
	1: 'Welcome! We are eager to advertise your business and your offers to our members and our extensive network. It’s easy too, you decide what you would like us to promote and we do it. You can make changes to your advertised items, services, or procedures every 30 days. We use multi-channel marketing to help your business grow. From local events and representatives to social media, radio, and more your business will be shared with thousands of individuals on a regular basis. No reason to wait, it’s FREE. Start your advertising today!',
	2: <ul>
		<li>Free advertising</li>
		<li>You decide your offers</li>
		<li>Client access and growth</li>
		<li>National exposure</li>
		<li>Ability to change your promotion once every 30 days</li>
		<li>National network strength</li>
	</ul>
};

export const CorporatePlanText = {
	1: 'For all businesses with a minimum of 5 memberships purchased, you get an incredible rate of only $49.95 per person per year. This is the most affordable program available to you and your employees. Two memberships at $49.95 (for a total of $99.90) a membership can be made into a family plan. We have numerous ways to structure a corporate plan and how memberships can be paid for. You can offer this to your employees and allow them to purchase it for themselves or you can purchase it for them. Contact us at 208-324-8171 or email info@deluxenetworkprogram.com for more information.',
	2: 'Business owners and leaders, it’s time to take care of your best assets, your employees. Help your employees save thousands of dollars at thousands of businesses locally and nationwide with a P4P Membership. They will save on dental, vision, and other medical needs as well as family fun, travel, food, retail and more! We are giving you the tool to make another positive impact in your employee’s lives, so get started today!'
}

export const FamilyPlanText = {
	1: 'Family Plans are made with the purchase of 2 memberships at $49.95 (for a total charge of $99.90.) All members of your immediate family are covered on a family plan, to include 2 adults and all children 18 and under or children under 26 that are still in college.We know how expensive it can be to care for a family and to ensure their needs are met. Start saving your family thousands today with a family membership. Get the things you need and want at a fraction of the normal costs with your P4P Membership, start saving today. Family plans are an incredible value!',
	2: '“We are self employed and don’t have any access to affordable medical, dental or vision care.  I have 5 children and your family plan allows us to make sure their needs are met as well as ours.  We really don’t know what we would have done without our P4P Membership.  Thank you, so much, for making an affordable program that lets us not only take care of our family’s necessities, but family fun and travel as well!” -The Smith Family'
}

export const FriendPageText = {
	1: 'Welcome Influencers! We are always excited to have new Influencers in P4P. We know many of our members and others who know of our amazing program, and the savings that consumers receive through it, are talking to friends and family about us! Let us compensate you for your efforts and loyalty! Sign up to be an Influencer and we will pay you an Influencer Referral Bonus worth 20% of the value of the sale. You will have no obligation and no requirements as an Influencer. It’s just our way of saying thank you for what you do, and for helping us grow!',
	2: <ul>
		<li>Get paid to refer people to the program</li>
		<li>No minimum requirements</li>
		<li>Advancement optional</li>
		<li>Have fun sharing what you love about P4P</li>
	</ul>,
}

export const IndividualPlanText = {
	1: 'Individual memberships will give one person a membership. For only $49.95 a year, a one-time annual purchase price, you unlock thousands of dollars in savings at thousands of our affiliates over the course of a year.',
	2: '“I love my P4P Membership. It has saved me hundreds on my dental care and now I save on travel and at places I love to shop. Thank you for making this program, without it I would not be able to afford the care and things I need.” - Loyal P4P Member for 9 years'
}

export const LoyaltyAffiliatePageText = {
	1: <ul style={{ textAlign: 'left' }}>
		<li>Memberships for you and your employees in the program.</li>
		<li>A monthly group listing on a local consumer’s social media page about your business that reaches over 300 people at a time.</li>
		<li>List employee listings with social media to get who you need.</li>
		<li>Ability to update specials, promotions, or programs you may have on the website.</li>
		<li>Complete access to all our networking events where you can have a free annual presentation at a local community event that can reach up to 3,000 people.</li>
		<li>Increased followers on your business social media site.</li>
		<li>Customized loyalty program.</li>
		<li>You also can receive revenues of the network of up to 50% of our profits.</li>
	</ul>,
	2: <ul style={{ padding: '10px' }}>
		<li>You receive everything that the basic business listing offers, but in addition, you also receive these items for only $99.95 a month (you can cancel at any time). </li>
		<li>Premier listing with our social media influencers instead of being listed in 1-10 businesses, you will be featured in your own listing.</li>
		<li>You are also listed as Premier on our website — being listed at the top of the page.</li>
		<li>Have a positive Google review placed for you every month that you are with the program.</li>
		<li>You are also listed on the P4P social media site with either a blog post, Facebook Live, or an ad on our social media sites.</li>
	</ul>
}

export const PricingPageText = {
	1: <>
		<p>
		Individual memberships provide full access for just $49.95 per month. This is a recurring monthly subscription that will automatically renew. As a P4P Member, you’ll enjoy exclusive savings on products, services, and procedures year-round. We continuously work to bring you unmatched discounts at top businesses both locally and nationwide.
		</p>
		<p>
		Easily browse elite businesses and their promotions to see how much you can save. And it’s not just for dental anymore—your membership unlocks savings at thousands of locations across various industries.
		</p>
		<p>
		Ready to start saving? Join today!
		</p>
	</>,
	2: <>
		<p>
	Family Plans include two memberships for just $49.95 per month each (totaling $99.90 per month). This plan covers your entire immediate family, including two adults and all children under 18, or children under 26 who are still in college.
		</p>
		<p>
	We understand how costly it can be to care for a family while ensuring their needs are met. With a P4P Family Membership, you can save thousands on essential products and services. Get what you need at a fraction of the cost and start saving today!
		</p>
		<p>
	Family plans offer unbeatable value—join now!
		</p>
	</>,
	3: 'For all businesses with a minimum of 5 memberships purchased, you get an incredible rate of only $49.95 per person per year. This is the most affordable program available to you and your employees. Two memberships at $49.95 (for a total of $99.90) a membership can be made into a family plan. We have numerous ways to structure a corporate plan and how memberships can be paid for. You can offer this to your employees and allow them to purchase it for themselves or you can purchase it for them. Contact us at 208-324-8171 or email info@deluxenetworkprogram.com for more information.'
}

export const RepresentativePageText = {
	1: <p>Welcome prospective educators. We are excited to have you join the P4P Educator ranks, where you get to control your work and financial destiny. As a P4P Educator you will promote memberships to our national loyalty programs and the thousands of business promotions and offers that are accessible to our members. You will be compensated for each membership you promote and as your network grows so does your compensation. You will also be able to work with business owners to help promote their loyalty programs. Contact us today to learn more. <strong>208-324-8171</strong> or email <strong>info@deluxenetworkprogram.com</strong></p>,
	2: <ul>
		<li>Be your own boss</li>
		<li>Join an established and proven company</li>
		<li>Work when you want and how you want </li>
		<li>Network with business owners and other professionals</li>
		<li>Control your income growth</li>
		<li>Be a part of something spectacular</li>
		<li>Help others</li>
		<li>Enjoy residual income</li>
		<li>Low minimum requirements</li>
		<li>Infinite growth potential</li>
	</ul>
}

export const SavingsPageText = {
	1: 'Finding a business and their offers is easy. Enter any information you would like and let us show you the savings you can find with a P4P Membership.  *Note: This is a small glimpse into the vast network of affiliates and their promotions. To access the complete listing and all current offers you must login.'
}

export const BusinessPlanListText = {
	1: <ul>
		<li>Everything the Deluxe Membership includes</li>
		<li>And Exclusivity in a local community being the only business in your profession represented </li>
		<li>Listing as the exclusive business in the monthly community newsletter</li>
	</ul>,


	2: <ul>
		<li>Everything the Exclusive Membership includes</li>
		<li>Plus, you will receive one ADDED feature each month starting with your Influencer post</li>
		<li>Influencer Post</li>
		<li>Private Podcast</li>
		<li>Presentation</li>
		<li>Local Listing</li>
		<li>National/Focal Listing</li>
	</ul>
};

export const NetworkingMembershipText = {
	1: <ul>
		<li>Be able to complete referrals in the program and get paid</li>
		<li>Save hundreds by shopping with small business using your membership</li>
		<li>Be able to attend monthly community meetings locally and focally</li>
		<li>Join development meetings as a community member</li>
		<li>Attend all live quarterly events</li>
		<li>Have the opportunity to lead in the community so you can share in the profits</li>
	</ul>,
	2: <ul>
		<li>Be listed on our focal/national platform as a business member</li>
		<li>Monthly podcast about you and your business out to the community + channels on iHeart, Spotify. and apple iTunes</li>
		<li>Increased reviews, likes, and follows on social media</li>
		<li>Access to sharing your news and post each month in the community</li>
		<li>Ability to teach and educate the community about what you do</li>
		<li>Save hundreds by shopping with small business using your membership</li>
		<li>Offer savings and free memberships to your employees</li>
		<li>Attend and participate in all live quarterly events</li>
		<li>Can be a leader in the community so you can share in the profits</li>
		<li>Complete training in the program</li>
		<li>Complete access for you and employees to attend ALL meetings and events except where exclusivity is established</li>
	</ul>,
};