import React, { Component } from 'react';
import { AffiliatesPageText } from '../assets/lang/english';
import { Link } from 'react-router-dom';
import IMG_BannerEducators from '../assets/images/partners/pic_banner_educators.jpg';
import IMG_Representative from '../assets/images/partners/pic_representative.jpg';
import IMG_Influencer from '../assets/images/partners/pic_friend.jpg';
import IMG_Friend from '../assets/images/partners/pic_friend_new.jpg';
import BecomeAffiliateModal from '../components/BecomeAffiliateModal';
import ButtonNormal from '../components/common/ButtonNormal';
import ReactGA from 'react-ga';
import { Button, Icon } from 'semantic-ui-react';
import { getQueryParam, copyToClipboard, getCurrentURLWithParam } from '../utils/common';

class AffiliatesPage extends Component {
	directLinkQueryParam = 'type'

	state = {
		becomeRepModal: false,
		becomeFriendModal: false,
		becomeInfluencerModal: false,
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		ReactGA.pageview('/educators');
		(window.Stripe && this.checkDirectLinkLanding()) || this.checkDirectLinkLandingOnStripeLoad();
	}

	close = () => {
		this.setState({
			becomeRepModal: null,
			becomeFriendModal: null,
			becomeInfluencerModal: null,
		});
	}

	toBackOffice = () => {
		window.open('https://office.peoplefourpeople.com', '_blank');
	}

	checkDirectLinkLanding = () => {
		const type = getQueryParam(this.directLinkQueryParam);
		type === 'rep' && this.state.becomeRepModal === false && this.setState({ becomeRepModal: true });
		type === 'friend' && this.state.becomeFriendModal === false && this.setState({ becomeFriendModal: true });
		type === 'influencer' && this.state.becomeInfluencerModal === false && this.setState({ becomeInfluencerModal: true });
	}

	checkDirectLinkLandingOnStripeLoad = () => {
		// Check direct landing once Stripe.js loads.
		document.querySelector('#stripe-js').addEventListener('load', () => {
			this.checkDirectLinkLanding();
		});
	};

	onPlanCopy = (type) => {
		const planDirectLink = getCurrentURLWithParam(this.directLinkQueryParam, type);
		copyToClipboard(planDirectLink);
	}

	render() {
		return (
			<main className="partners">
				<section className="header">
					{/* <h1 className="header__h1">DNP Educators</h1> */}

					<img src={IMG_BannerEducators} style={{paddingTop: "60px"}} className="header__image" />
					<p className="header__paragraph">{AffiliatesPageText[1]}</p>
				</section>
				
				<section className="content">
					<div>
						<div className="content__card content__card-representative">
							{ this.state.becomeFriendModal && <BecomeAffiliateModal show={this.state.becomeFriendModal} onHide={this.close} friend />}
							<div className="content__card__header">
								<h3 className="content__card__header__text">Friend</h3>
							</div>
							<img src={IMG_Friend} alt="Represntative Image" className="content__card__image" />
							<div className="content__card__content">
								<p className="content__card__content__paragraph">
									{AffiliatesPageText[6]}
								</p>
								<div className="content__card__content__buttons">
									{/* <Link to="/educators/friend"><ButtonNormal outline color="lightred" nolineheight>More</ButtonNormal></Link> */}
									<ButtonNormal color="purple" nolineheight onClick={() => this.setState({ becomeFriendModal: true })}>Sign up</ButtonNormal>
									<Button size="massive" onClick={() => this.onPlanCopy('friend')}>
										<Icon name='clipboard' style={{ textAlign: 'right' }} />
									</Button>
								</div>
							</div>
						</div>

						<div className="content__card content__card-representative">
							{ this.state.becomeInfluencerModal && <BecomeAffiliateModal show={this.state.becomeInfluencerModal} onHide={this.close} influencer />}
							<div className="content__card__header">
								<h3 className="content__card__header__text">Service Member</h3>
							</div>
							<img src={IMG_Influencer} alt="Represntative Image" className="content__card__image" />
							<div className="content__card__content">
								<p className="content__card__content__paragraph">
									{AffiliatesPageText[3]}
								</p>
								<div className="content__card__content__buttons">
									<Link to="/educators/friend"><ButtonNormal color="lightblue" nolineheight outline>More</ButtonNormal></Link>
									<ButtonNormal nolineheight color="lightblue" onClick={() => this.setState({ becomeInfluencerModal: true })}>Sign up</ButtonNormal>
									<Button size="massive" onClick={() => this.onPlanCopy('influencer')}>
										<Icon name='clipboard' style={{ textAlign: 'right' }} />
									</Button>
								</div>
							</div>
						</div>


					</div>

					<div>
						<div className="content__card content__card-representative">
							{ this.state.becomeRepModal && <BecomeAffiliateModal show={this.state.becomeRepModal} onHide={this.close} type={3} />}
							<div className="content__card__header">
								<h3 className="content__card__header__text">Educator</h3>
							</div>
							<img src={IMG_Representative} alt="Represntative Image" className="content__card__image" />
							<div className="content__card__content">
								<p className="content__card__content__paragraph">
									{AffiliatesPageText[2]}
								</p>
								<div className="content__card__content__buttons">
									<Link to="/educators/representative"><ButtonNormal outline color="lightred" nolineheight>More</ButtonNormal></Link>
									<ButtonNormal color="lightred" nolineheight onClick={() => this.setState({ becomeRepModal: true })}>Sign up</ButtonNormal>
									<Button size="massive" onClick={() => this.onPlanCopy('rep')}>
										<Icon name='clipboard' style={{ textAlign: 'right' }} />
									</Button>
								</div>
							</div>
						</div>

					</div>
				</section>

				<div style={{ backgroundColor: '#fff', height: '20px' }} />

				<section className="backoffice">
					<div className="backoffice__content">
						<button className="btn btn-right" onClick={this.toBackOffice}>Back Office</button>
					</div>
				</section>
			</main>

		);
	}
}

export default AffiliatesPage;