import React from 'react';
import axios from 'axios';
import SelectReferenceInput from '../../form/SelectReferenceInput';
import FSCheckbox from '../../form/FSCheckbox';
import SignatureCanvasInput from '../../form/SignatureCanvasInput';
import styles from './BecomeAffiliateForms.module.scss';

const MoreInformationPage = ({ type: t }) => {
	const [contract, setContract] = React.useState('');
	const [privacyPolicy, setPrivacyPolicy] = React.useState('');
	const [policyProcedureManual, setPolicyProcedureManual] = React.useState('');

	React.useEffect(() => {
		(async () => {
			const type = t === 1 ? 'INFLUENCER' : 'REPRESENTATIVE';
			const res = await axios.post('/api/contract', { type });
			setContract(res.data.contract);
			const res2 = await axios.post('/api/contract', {type: 'PRIVACYPOLICY'});
			setPrivacyPolicy(res2.data.contract);
			const res3 = await axios.post('/api/contract', {type: 'POLICYPROCEDUREMANUAL'});
			setPolicyProcedureManual(res3.data.contract);
		})();
	}, []);

	return (
		<>
			<h2 className={styles.header}>Reference</h2>
			<p className={styles.subheader}>Did someone refer you? Search their name below!</p>
			<SelectReferenceInput label="Reference" placeholder="Reference" name="reference" />
			<div className={styles.seperator} />				
			<div className="contract">
				<p className={styles.subheader}>By signing below, you agree to our terms and services and that you provided correct information on the W-9</p>
				<div className="contract__box" dangerouslySetInnerHTML={{__html: contract}}></div>
				<FSCheckbox name="contractAgree" label="I understand and agree to the membership terms" />
			</div>
			<div className="contract">
				<p className={styles.subheader}>By signing below, you agree to our terms and privacy policy</p>
				<div className="contract__box" dangerouslySetInnerHTML={{__html: privacyPolicy}}></div>
				<FSCheckbox name="privacyAgree" label="I understand and agree to our terms and privacy policy" />
			</div>
			<div className="contract">
				<p className={styles.subheader}>By signing below, you agree to our Policy Procedure Manual</p>
				<div className="contract__box" dangerouslySetInnerHTML={{__html: policyProcedureManual}}></div>
				<FSCheckbox name="policyAgree" label="I understand and agree to our Policy Procedure Manual" />
			</div>
			<SignatureCanvasInput name='signature' />
		</>
	);
};

export default MoreInformationPage;

export const validate = (values) => {
	const errors = {};

	if(!values.contractAgree) {
		errors.contractAgree = 'Required';
	}

	if(!values.signature) {
		errors.signature = 'Please sign above to continue';
	}

	if(!values.privacyAgree) {
		errors.privacyAgree = 'Required';
	}

	if(!values.reference) {
		errors.reference = 'Required';
	}

	if(!values.policyAgree) {
		errors.policyAgree = 'Required';
	}

	return errors;
};