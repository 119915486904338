import React, { Component } from 'react';
import { Form } from 'react-final-form';
import LoadingForm from '../components/common/LoadingForm';
import TextInput from '../components/form/TextInput';
import TextAreaInput from '../components/form/TextAreaInput';
import { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/react-hoc';
import { Form as SemanticForm } from 'semantic-ui-react';

import IMG_Banner from '../assets/images/common/pic_banner_more.jpg';
import ButtonNormal from '../components/common/ButtonNormal';
import withStateMutation from '../components/withStateMutation';
import mutationSendCorporateMessage from '../mutations/sendCorporateMessage';
import ReactGA from 'react-ga';

class ContactPage extends Component {
	state = {
		finished: false
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		ReactGA.pageview('/contact');
	}

	onSubmit = values => {
		this.props.mutate({
			variables: values
		}).then(result => {
			if(!this.props.mutateError) {
				this.setState({
					finished: true
				});
				// Reset form fields after submission
				this.form.reset(); // Resetting the form
			}
		});
	}

	renderForm = () => {
		if(!this.state.finished) {
			return(
				<div className="contactpage__form">
					<h3>Send a Message</h3>
					<Form
						onSubmit={this.onSubmit}
						validate={validate}
						>
							
						{({ handleSubmit, submitting, values }) => (

							<SemanticForm loading={this.props.mutateLoading} onSubmit={handleSubmit}>
								<TextInput name="name" placeholder="Full Name" required />
								<TextInput name="subject" placeholder="Subject" required />
								<TextInput name="email" placeholder="Reply Email" required />
								<TextAreaInput name="message" placeholder="Message" required />
								<ButtonNormal color="lightred" style={{float: 'right'}} type="submit">Submit</ButtonNormal>
							</SemanticForm>

						)}
					</Form>
				</div>
			);
		}
	}

	renderFinished = () => {
		if(this.state.finished) {
			return (
				<div className="purchase-complete">
					<i class="fas fa-check"></i>
					<h4>Message Sent!</h4>
					<p>Give us 24-48 hours to review your message and we will be in touch!</p>
				</div>
			);
		}
	}

	render() {
		return (
			<main className="contactpage">
				<div className="contactpage__header">
					<h1 className="header__h1">Contact</h1>
					<img src={IMG_Banner} className="header__image" />
					<p className="header__paragraph"></p>
				</div>

				<div className="contactpage__info">
					<div className="contactpage__info__box">
						<h2>Corporate Headquarters</h2>
						<p>PO Box 416</p>
						<p>Rexburg, ID 83440</p>
					</div>

					<div className="contactpage__info__box">
						<h2>General Contact</h2>
						<p>During the time of the coronavirus, our only method of contact will be through email. Thank you for your understanding and patience.</p>
						<p>(208) 324-8171</p>
						<p>(888) 567-7677</p>
						<p>Mon - Fri : 9am-5pm MST</p>
						<p>info@p4pcommunity.com</p>
					</div>
				</div>

				{this.renderForm()}
				{this.renderFinished()}
			</main>
		);
	}
}

function validate(values) {
	const errors = {};

	if(!values.name) {
		errors.name = 'Required Field';
	}

	if(!values.subject) {
		errors.subject = 'Required Field';
	}

	if(!values.email) {
		errors.email = 'Required Field';
	}

	if(!values.message) {
		errors.message = 'Required Field';
	}

	return errors;
}

export default compose(
	graphql(mutationSendCorporateMessage),
	withStateMutation()
)(ContactPage);