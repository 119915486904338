import React, { Component } from 'react';
import Modal from './Modal';
import BecomeAffiliate from './common/BecomeAffiliate';
import BecomeInfluencer from './common/BecomeInfluencer';
import BecomeFriend from './common/BecomeFriend';

const types = [
	'N/A',
	'Become an Influencer ',
	'Join our Business Loyalty Program',
	'Become an Educator',
];

const BecomeAffiliateModal = ({ type, code, friend, influencer, onComplete, ...props }) => {
	let Comp = null;
	let title = '';

	if (type !== null && type !== undefined) {
		Comp = (
			<BecomeAffiliate
				type={type}
				code={code}
				onComplete={onComplete}
			/>
		);
		title = types[type];
	} else if (friend) {
		Comp = (
			<BecomeFriend
				onComplete={onComplete}
			/>
		);
		title = 'Become a Friend';
	} else if (influencer) {
		Comp = (
			<BecomeInfluencer
				onComplete={onComplete}
			/>
		);
		title = 'Become a Influencer';
	}

	return (
		<Modal size='large' { ...props }>
			<div className="modal-header modal-login-header">{title}</div>
			<div className="modal-body">
				{Comp}
			</div>
		</Modal>
	);
};

export default BecomeAffiliateModal;