import React, { Component, Fragment } from 'react';
import getStoreTypeAndImage from '../../utils/getStoreTypeAndImage';
import ButtonNormal from '../common/ButtonNormal';
import { Segment, Button } from 'semantic-ui-react';
import PromotionsModal from './PromotionsModal';
import _ from 'lodash';


function createURL(logo) {
	if(process.env.NODE_ENV === 'production') {
		return 'https://office.peoplefourpeople.com' + logo;
	}
	return 'http://localhost:5002' + logo;
}

class Store extends Component {
	state = {
		modal: false
	}

	renderButton = () => {
		const { storeInformation } = this.props.data;
		const { promotionsCount, promotionsDisabled } = storeInformation;
		return(
			<Fragment>
				{!!promotionsCount && <Button type='button' disabled={promotionsDisabled} inverted color='blue' onClick={() => this.setState({ modal: true })}>{!promotionsDisabled ? 'Check Promotions' : 'Login to check Promotions'}</Button>}
			</Fragment>
		)
	}

	render() {

		if(!_.get(this.props, 'data.storeInformation')) {
			return <div></div>
		}

		const { locationInformation, storeInformation, nationalLocationPlaceholder } = this.props.data;
		const { businessname, promotions, promotionsCount, promotionsDisabled, logo, category } = storeInformation;
		const { street1, street2, city, state, zip, country } = locationInformation;
		const type = category ? (category.length > 1 ? 11 : _.get(this.props, 'data.storeInformation.category[0]', 11)) : 11;
		const typeInfo = getStoreTypeAndImage[type-1];

		const categories = category ? category.reduce((prev, id, i) => {
			const info = getStoreTypeAndImage[id-1];
			return prev + (i ? ', ' : '') + info?.label;
		}, '') : 'Other';
		const isPremier = _.get(storeInformation, 'premier', false);

		return (
			<Segment className="store" stacked raised color={isPremier ? 'blue' : null}>
				{this.state.modal && <PromotionsModal show={this.state.modal} id={this.props.data.id} promotions={promotions} onHide={() => this.setState({ modal: false })} />}
				<div>
					<img 
						src={logo ? createURL(logo) : typeInfo?.icon}
						alt="IconImage"
						className={logo ? 'imageLogo' : 'imageIcon'}
					/>
					<h4>{categories}</h4>
					<h2>{businessname}</h2>
				</div>
				{	nationalLocationPlaceholder ?
					<div>
						<h3>National Location</h3>
					</div>
					:
					<div className="store__address">
						<p>{street1}</p>
						{street2 && <p>{street2}</p>}
						<p>{city}, {state} {zip}</p>
					</div>
				}
				<div>
					{!!promotionsCount && <p>{promotionsCount} promotion{promotionsCount > 1 && 's'} available</p>}
					{this.renderButton()}
				</div>
			</Segment>
		);
	}
}

export default Store;