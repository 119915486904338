import React from 'react';
import LoginOrRegister from '../LoginOrRegister/LoginOrRegister';
import { Button } from 'semantic-ui-react';
import Wizard from './Wizard';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/react-hooks';
import MoreInformationPage, { validate as MoreInformationValidate } from './MoreInformationPage';
import YourInformationPage, { validate as YourInformationPageValidate } from './YourInformationPage';
import QUERY_SIGNUP_USER from '../../../queries/signupUser';
import GraphQLError from '../../form/GraphQLError';
import PaymentPage, { validate as PaymentPageValidate } from '../BuyCard/PaymentPage';
import FamilyMembersPage, { validate as FamilyMembersPageValidate } from '../BuyCard/FamilyMembersPage';
import SelectSignup from './SelectSignup';
import { injectStripe, Elements, StripeProvider } from 'react-stripe-elements';
import MUTATION_BECOME_INFLUENCER from './becomeAffiliate';
import { WizardContext } from './Wizard';


const AlreadySignedUp = () => {
	return (
		<div className="purchase-complete" style={{ textAlign: 'center' }}>
			<i className="fas fa-check" />
			<h3>You're all set!</h3>
			<p>You are signed up to be an influencer.<br />We will contact you shortly with more information.</p>
			{/* <Button
				content="Go to the Backoffice"
				size="huge"
				color="blue"
				type="button"
				onClick={() => window.open('https://office.peoplefourpeople.com', '_blank')}
			/> */}
		</div>
	);
};

const RenderForm = ({ purchaseMembership=null, hasW9=false, hasW8=false, discountCode, setDiscountCode }) => {

	return [
		...(purchaseMembership === 1 ?
			[
				<Wizard.Page validate={FamilyMembersPageValidate} key="FamilyMembersPage">
					<FamilyMembersPage />
				</Wizard.Page>
			]
			: []),

		<Wizard.Page validate={YourInformationPageValidate} key="YourInformationPage">
			<YourInformationPage needAddress={true} {...{ purchaseMembership, hasW9, hasW8 }} />
		</Wizard.Page>,

		<Wizard.Page validate={MoreInformationValidate} key="MoreInformationPage">
			<MoreInformationPage />
		</Wizard.Page>,

		...((purchaseMembership === 0 || purchaseMembership === 1) ?
			[
				<Wizard.Page validate={PaymentPageValidate} key="PaymentPage">
					{(mutators) => (
						<WizardContext.Consumer>
							{values => 
								<PaymentPage
									affiliate={true}
									code={discountCode}
									plan={purchaseMembership}
									onCodeApplied={code => setDiscountCode(code)}
									values={values}
									setFree={mutators.setFree}
								/>
							}
						</WizardContext.Consumer>
					)}
				</Wizard.Page>


			]
			: []),
	];
};

const BecomeAffiliateContent = ({ code, onComplete, stripe }) => {
	const [becomeInfluencer, { data: mData, loading: mLoading, error: mError }] = useMutation(MUTATION_BECOME_INFLUENCER);
	const { data, loading, refetch } = useQuery(QUERY_SIGNUP_USER, {
		fetchPolicy: 'network-only',
	});

	const [purchaseMembership, setPurchaseMembership] = React.useState(null);
	const [discountCode, setDiscountCode] = React.useState(null);

	const user = _.get(data, 'user', {}) || {};

	if(loading) { 
		return <div>Loading...</div>;
	}

	
	if(!_.get(data, 'user.id')) {
		return <LoginOrRegister onLogin={() => refetch()} />;
	}
	
	if(_.get(data, 'user.influencer', false)) {
		return (
			<AlreadySignedUp />
		);
	}

	const hasValidMembership = _.get(data, 'user.hasValidMembership', false);

	if(!hasValidMembership && purchaseMembership === null) {
		setPurchaseMembership(0);
		// return (
		// 	<SelectSignup
		// 		setPurchaseMembership={setPurchaseMembership}
		// 	/>
		// );
	}

	const onSubmit = async (v) => {
		const { stripeName, stripeZip, isFree, ...variables} = { ...v };
		const { address, phone, birthday, reference, purchaseMembership, hasW9, w9, hasW8, w8 } = variables;
		const vars = {
			address, phone, birthday, reference, purchaseMembership, hasW9, w9, hasW8, w8, discountCode
		};

		console.log("w9", w9);
		vars.signature = _.get(variables, 'signature.trimmed');
		vars.membership = {
			...variables.membership,
			spouseFirstName: variables.spouseFirstName,
			spouseLastName: variables.spouseLastName,
			members: variables.members,
		};

		if(purchaseMembership !== null && !isFree) {
			stripe.createToken({
				'name': stripeName,
				'address_zip': stripeZip,
			}).then(({ token, error }) => {
				if(error || !token.id) {
					alert('The debit/credit card that was used is invalid.');
				} else {
					vars.membership.token = token.id;
					becomeInfluencer({
						variables: {
							...vars,
							discountCode: _.get(discountCode, 'id', null),
						}});
				}
			});
		} else if (purchaseMembership !== null && isFree) {
			vars.membership.token = 'none';
			becomeInfluencer({ 
				variables: {
					...vars,
					discountCode: _.get(discountCode, 'id', null)
				}
			});
		} else {
			becomeInfluencer({ variables: { ...vars } });
		}
	};

	return (
		<div>
			<Wizard
				initialValues={{
					code,
					purchaseMembership,
					hasW9: user.hasW9,
					hasW8: user.hasW8,
					address: {}
				}}
				onSubmit={onSubmit}
				loading={mLoading}
			>
				{ RenderForm({ 
					purchaseMembership,
					hasW9: user.hasW9,
					hasW8: user.hasW8,
					discountCode,
					setDiscountCode
				})}
			</Wizard>
			<GraphQLError error={mError}>There was an problem</GraphQLError>
		</div>
	);
};

const _BecomeAffiliateContent = injectStripe(BecomeAffiliateContent);

const BecomeAffiliate = (props) => {
	return (
		<StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
			<Elements>
				<_BecomeAffiliateContent {...props} />
			</Elements>
		</StripeProvider>
	);
};

export default BecomeAffiliate;