import React, { Component } from 'react';
import axios from 'axios';
import _, { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/react-hoc';
import withStateMutation from '../../withStateMutation';

import Wizard from './Wizard';
import ShippingPage, { validate as shippingPageValidate } from './ShippingPage';
import DetailsPage, { validate as detailsPageValidate } from './DetailsPage';
import FamilyMembersPage from './FamilyMembersPage';
import WithAccountPage from './WithAccountPage';
import PaymentPage, { validate as paymentPageValidate } from './PaymentPage';
import ErrorMessage from '../ErrorMessage';
import mutationBuyCard from '../../../mutations/buyCard';
import { Button } from 'semantic-ui-react';
import { injectStripe, Elements, StripeProvider } from 'react-stripe-elements';
import { WizardContext } from './Wizard';

class BuyCardContent extends Component {
	state = {
		accountVerified: false,
		accountID: '',
		contract: '',
		finished: false,
		loading: false,
		id: null,
		actionValidateAddress: false,
		validatedAddress: false,
		addressValues: null,
		code: null
	}

	async componentDidMount() {
		if(!this.state.contract) {
			const res = await axios.post('/api/contract', { type: 'MEMBER'});
			this.setState({ contract: res.data.contract });
		}
	}

	onSubmit = async ({stripeName, stripeZip, isFree, ...values}) => {
		if(stripeName && stripeZip && !isFree) {
			this.props.stripe.createToken({
				'name': stripeName,
				'address_zip': stripeZip,
			}).then(({ token, error }) => {
				if(error || !token.id) {
					alert('The debit/credit card that was used is invalid.');
				} else {
					this.props.mutate({
						variables: { 
							...values,
							token: token.id,
							discountCode: _.get(this.state.code, 'id', null),
						}
					}).then(result => {
						if(!this.props.mutateError) {
							this.setState({
								finished: true,
								id: _.get(this.props.mutateResult, 'data.buyCard.id'),
							})
						}
					});
				}
			});
		} else if(isFree) {
			this.props.mutate({
				variables: { 
					...values,
					token: 'none',
					discountCode: _.get(this.state.code, 'id', null),
				}
			}).then(result => {
				if(!this.props.mutateError) {
					this.setState({
						finished: true,
						id: _.get(this.props.mutateResult, 'data.buyCard.id'),
					});
				}
			});
		}
	}

	renderShippingPage = () => {
		return(
			<Wizard.Page
				validate={shippingPageValidate}
				requiresAddressValidation
			>
				{({ setAddressValues }) => (
					<ShippingPage
						addressValidation={this.state.actionValidateAddress}
						values={this.state.addressValues}
						goBack={() => this.setState({ actionValidateAddress: false, validatedAddress: false, })}
						confirm={async (values) => {
							setAddressValues(values.street1, values.street2, values.city, values.state, values.zip, values.country);
							await this.setState({
								actionValidateAddress: false,
								validatedAddress: true,
							});
							this.formRef.handleSubmit();
						}}
					/>
				)}
			</Wizard.Page>
		);
	}

	renderFamilyPage = () => {
		return(
			<Wizard.Page>
				<FamilyMembersPage />
			</Wizard.Page>
		);
	}

	renderDetailsPage = () => {
		return(
			<Wizard.Page validate={detailsPageValidate}>
				<DetailsPage contract={this.state.contract} />
			</Wizard.Page>
		);
	}
	
	renderPaymentPage = () => {
		const plan = this.props.plan || 0;
		return(
			<Wizard.Page validate={paymentPageValidate}>
				{(mutators) => (
					<WizardContext.Consumer>
						{values => 
							<PaymentPage
								plan={plan}
								code={this.state.code}
								values={values}
								onCodeApplied={(code) => this.setState({ code })}
								setFree={mutators.setFree}
							/>
						}
					</WizardContext.Consumer>
				)}
			</Wizard.Page>
		);
	}

	renderIndividualPlan = () => {
		const { accountVerified, accountID } = this.state;
		return(
			<Wizard 
				initialValues={{ accountID, plan: 0  }}
				onSubmit={this.onSubmit}
				loading={this.props.mutateLoading}
				validatedAddress={this.state.validatedAddress}
				onValidateAddress={(values) => this.setState({ actionValidateAddress: true, addressValues: values })}
				setRef={ref => this.formRef = ref}
				resetAddressValidation={() => this.setState({ validatedAddress: false, })}
			>
				{this.renderShippingPage()}
				{this.renderDetailsPage()}
				{this.renderPaymentPage()}
			</Wizard>
		)
	}

	renderFamilyPlan = () => {
		const { accountVerified, accountID } = this.state;
		return(
			<Wizard
				initialValues={{ accountID, plan: 1 }}
				onSubmit={this.onSubmit}
				loading={this.props.mutateLoading}
				validatedAddress={this.state.validatedAddress}
				onValidateAddress={(values) => this.setState({ actionValidateAddress: true, addressValues: values })}
				setRef={ref => this.formRef = ref}
				resetAddressValidation={() => this.setState({ validatedAddress: false, })}
			>
				{this.renderShippingPage()}
				{this.renderFamilyPage()}
				{this.renderDetailsPage()}
				{this.renderPaymentPage()}
			</Wizard>
		)
	}

	renderPlan = () => {
		const plan = this.props.plan || 0;
		if(plan === 0) {
			return this.renderIndividualPlan();
		} else if(this.props.plan === 1) {
			return this.renderFamilyPlan();
		}
	}

	openReceipt = () => {
		if(this.state.id) {
			const production = process.env.NODE_ENV === 'production';
			const id = this.state.id;
			const url = production
				? 'https://office.peoplefourpeople.com/api/receipt?id=' + id
				: 'http://localhost:5002/api/receipt?id=' + id;
			window.open(url, '_blank');
		}
	}

	renderFinished = () => {
		return(
			<div className="purchase-complete">
				<i class="fas fa-check"></i>
				<h3>You're all set!</h3>
				<p>Thanks for buying the Everything Card</p>
				<p>We are processing your order and it should arrive within 7-10 business days.</p>
				<Button className="receiptButton" onClick={this.openReceipt}>View/Print Your Receipt</Button>
			</div>
		);
	}

	render() {
		const { accountVerified, accountID } = this.state;

		if(this.state.finished) {
			return this.renderFinished();
		}

		if(!this.state.accountVerified) {
			return(
				<WithAccountPage continue={ (accountID) => this.setState({ accountID, accountVerified: true }) } />
			)
		}

		let error = "";

		if(this.props.mutateError) {
			error = _.get(this.props.mutateError, 'graphQLErrors[0]message', 'No error');
		}

		return (
			<div>
				{this.renderPlan()}
				<ErrorMessage error={error}>There was an Problem with your purchase</ErrorMessage>
			</div>
		);
	}
}

const _BuyCardContent = injectStripe(BuyCardContent);

const BuyCard = (props) => {
	return (
		<StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
			<Elements>
				<_BuyCardContent {...props} />
			</Elements>
		</StripeProvider>
	);
}

export default compose(
	graphql(mutationBuyCard),
	withStateMutation(),
)(BuyCard);