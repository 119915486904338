//Sandbox

//export const payPlans = [
// {
//    title: "Basic Networking Membership",
// price: "$99.90/year",
// plan: 8,
// discountCode: "net50",
// freeCode: "net000",
// planID: "P-5E200840FR523924UMTFKHQI",
// discountedPlanID: "P-10H538849S7965207MTFKG6I",
// },
// {
// title: "Deluxe Membership",
// price: "$99.90/month",
//  plan: 9,
//discountCode: "del50",
// freeCode: "del000",
// planID: "P-65929893C09302027MTFKGIY",
// discountedPlanID: "P-9A458760VS8521915MTFKETY",
// },
// {
// title: "Social Business Membership",
// price: "$149.85/month",
// plan: 6,
// discountCode: "soc33",
// freeCode: "soc000",
// planID: "P-8R5276397A664593GMTFKEAI",
// discountedPlanID: "P-21277279ND703110YMTFKDEY",
// },
//{
// title: "Exclusive Business Membership",
// price: "$199.80/month",
// plan: 7,
// discountCode: "exc25",
// discountCode1: "exc75",
// freeCode: "exc000",
// planID: "P-8AB84816T30145833MTFKCLI",
// discountedPlanID: "P-61Y98234P1306435BMTFKBTA",
//discountedPlanID1: "P-2EH31182P9512911AMTFKAYA",
//},
//];

//Live

export const payPlans = [
	{
		title: 'Community Educator Membership',
		price: '$99.90/month',
		plan: 8,
		discountCode: 'net49',
		freeCode: 'net100',
		planID: 'P-2BK29288DT954722PM7VAOQQ',
		discountedPlanID: 'P-4LL30228FW993140PM7VAPLI',
	},
	{
		title: 'Deluxe Membership',
		price: '$149.85/month',
		plan: 9,
		discountCode: 'DELUXE33OFFNOW', //$99.90
		discountCode1: 'DELUXE67OFF', //$49.95
		freeCode: 'del100',
		planID: 'P-7M934736S8665644XM7VAQKI',
		discountedPlanID: 'P-9K463971F47350741M7VARII',
		discountedPlanID1: 'P-7B813459RP2169238M7VASKY',
	},
	{
		title: 'Exclusive Membership',
		price: '$199.80/month',
		plan: 6,
		discountCode: 'EXCLUSIVE25OFF', // $149.85
		discountCode1: 'EXCLUSIVE50', // $99.90
		discountCode2: 'EXCLUSIVE75OFFNOW', // $49.95
		freeCode: 'soc100',
		planID: 'P-42J27301EL300071KM7VAU5I',
		discountedPlanID: 'P-59D30224TM218625RM7VAVRQ',
		discountedPlanID1: 'P-81W77793BA223801DM7VAWDY',
		discountedPlanID2: 'P-6AX63953RW615723PM7VAWXY',
	},
	{
		title: 'Business PLUS Membership',
		price: '$249.75/month',
		plan: 7,
		discountCode: 'BUSINESS20', // $199.80
		discountCode1: 'BUSINESS40OFF', // $149.85
		discountCode2: 'BUSINESS60NOW', // $99.90
		discountCode3: 'OFFBUSINESS80', // $49.95
		freeCode: 'exc100',
		planID: 'P-01453863N8041171UM7VAXQY',
		discountedPlanID: 'P-9KJ63418VG516952PM7VAYGQ',
		discountedPlanID1: 'P-7CF653935V555670CM7VAZAY',
		discountedPlanID2: 'P-1V787844R5597401EM7VAZTY',
		discountedPlanID3: 'P-2T779631LM8274228M7VA2DA',
	},
];
