import gql from "graphql-tag";

const MUTATION_BECOME_INFLUENCER = gql`
  mutation BecomeInfluencer(
    $address: ShippingAddress!
    $phone: String!
    $birthday: String!
    $signature: String!
    $reference: String
    $purchaseMembership: Int
    $membership: IMembership
    $hasW9: Boolean
    $w9: W9
    $w8: W8
    $hasW8: Boolean
    $discountCode: String
  ) {
    becomeInfluencer(
      address: $address
      phone: $phone
      birthday: $birthday
      signature: $signature
      reference: $reference
      purchaseMembership: $purchaseMembership
      membership: $membership
      hasW9: $hasW9
      hasW8: $hasW8
      w9: $w9
      w8: $w8
      discountCode: $discountCode
    ) {
      id
      influencer
    }
  }
`;

export default MUTATION_BECOME_INFLUENCER;
