export const countries = [
	{ value: 'AF', label: 'Afghanistan' },
	{ value: 'AL', label: 'Albania' },
	{ value: 'DZ', label: 'Algeria' },
	{ value: 'AD', label: 'Andorra' },
	{ value: 'AO', label: 'Angola' },
	{ value: 'AG', label: 'Antigua and Barbuda' },
	{ value: 'AR', label: 'Argentina' },
	{ value: 'AM', label: 'Armenia' },
	{ value: 'AU', label: 'Australia' },
	{ value: 'AT', label: 'Austria' },
	{ value: 'AZ', label: 'Azerbaijan' },
	{ value: 'BS', label: 'Bahamas' },
	{ value: 'BH', label: 'Bahrain' },
	{ value: 'BD', label: 'Bangladesh' },
	{ value: 'BB', label: 'Barbados' },
	{ value: 'BY', label: 'Belarus' },
	{ value: 'BE', label: 'Belgium' },
	{ value: 'BZ', label: 'Belize' },
	{ value: 'BJ', label: 'Benin' },
	{ value: 'BT', label: 'Bhutan' },
	{ value: 'BO', label: 'Bolivia' },
	{ value: 'BA', label: 'Bosnia and Herzegovina' },
	{ value: 'BW', label: 'Botswana' },
	{ value: 'BR', label: 'Brazil' },
	{ value: 'BN', label: 'Brunei' },
	{ value: 'BG', label: 'Bulgaria' },
	{ value: 'BF', label: 'Burkina Faso' },
	{ value: 'BI', label: 'Burundi' },
	{ value: 'KH', label: 'Cambodia' },
	{ value: 'CM', label: 'Cameroon' },
	{ value: 'CA', label: 'Canada' },
	{ value: 'CV', label: 'Cape Verde' },
	{ value: 'CF', label: 'Central African Republic' },
	{ value: 'TD', label: 'Chad' },
	{ value: 'CL', label: 'Chile' },
	{ value: 'CN', label: 'China' },
	{ value: 'CO', label: 'Colombia' },
	{ value: 'KM', label: 'Comoros' },
	{ value: 'CG', label: 'Congo (Brazzaville)' },
	{ value: 'CD', label: 'Congo (Kinshasa)' },
	{ value: 'CR', label: 'Costa Rica' },
	{ value: 'HR', label: 'Croatia' },
	{ value: 'CU', label: 'Cuba' },
	{ value: 'CY', label: 'Cyprus' },
	{ value: 'CZ', label: 'Czechia' },
	{ value: 'DK', label: 'Denmark' },
	{ value: 'DJ', label: 'Djibouti' },
	{ value: 'DM', label: 'Dominica' },
	{ value: 'DO', label: 'Dominican Republic' },
	{ value: 'EC', label: 'Ecuador' },
	{ value: 'EG', label: 'Egypt' },
	{ value: 'SV', label: 'El Salvador' },
	{ value: 'GQ', label: 'Equatorial Guinea' },
	{ value: 'ER', label: 'Eritrea' },
	{ value: 'EE', label: 'Estonia' },
	{ value: 'ET', label: 'Ethiopia' },
	{ value: 'FI', label: 'Finland' },
	{ value: 'FR', label: 'France' },
	{ value: 'DE', label: 'Germany' },
	{ value: 'GR', label: 'Greece' },
	{ value: 'IN', label: 'India' },
	{ value: 'ID', label: 'Indonesia' },
	{ value: 'IR', label: 'Iran' },
	{ value: 'IQ', label: 'Iraq' },
	{ value: 'IE', label: 'Ireland' },
	{ value: 'IT', label: 'Italy' },
	{ value: 'JP', label: 'Japan' },
	{ value: 'MX', label: 'Mexico' },
	{ value: 'RU', label: 'Russia' },
	{ value: 'SA', label: 'Saudi Arabia' },
	{ value: 'ZA', label: 'South Africa' },
	{ value: 'ES', label: 'Spain' },
	{ value: 'SE', label: 'Sweden' },
	{ value: 'CH', label: 'Switzerland' },
	{ value: 'GB', label: 'United Kingdom' },
	{ value: 'US', label: 'United States' },
	{ value: 'VN', label: 'Vietnam' },
	{ value: 'ZW', label: 'Zimbabwe' }
  ];  

export const states = [
	{ label: 'Alabama', value: 'AL' },
	{ label: 'Alaska', value: 'AK' },
	{ label: 'Arizona', value: 'AZ' },
	{ label: 'Arkansas', value: 'AR' },
	{ label: 'California', value: 'CA' },
	{ label: 'Colorado', value: 'CO' },
	{ label: 'Connecticut', value: 'CT' },
	{ label: 'Delaware', value: 'DE' },
	{ label: 'Florida', value: 'FL' },
	{ label: 'Georgia', value: 'GA' },
	{ label: 'Hawaii', value: 'HI' },
	{ label: 'Idaho', value: 'ID' },
	{ label: 'Illinois', value: 'IL' },
	{ label: 'Indiana', value: 'IN' },
	{ label: 'Iowa', value: 'IA' },
	{ label: 'Kansas', value: 'KS' },
	{ label: 'Kentucky', value: 'KY' },
	{ label: 'Louisiana', value: 'LA' },
	{ label: 'Maine', value: 'ME' },
	{ label: 'Maryland', value: 'MD' },
	{ label: 'Massachusetts', value: 'MA' },
	{ label: 'Michigan', value: 'MI' },
	{ label: 'Minnesota', value: 'MN' },
	{ label: 'Mississippi', value: 'MS' },
	{ label: 'Missouri', value: 'MO' },
	{ label: 'Montana', value: 'MT' },
	{ label: 'Nebraska', value: 'NE' },
	{ label: 'Nevada', value: 'NV' },
	{ label: 'New Hampshire', value: 'NH' },
	{ label: 'New Jersey', value: 'NJ' },
	{ label: 'New Mexico', value: 'NM' },
	{ label: 'New York', value: 'NY' },
	{ label: 'North Carolina', value: 'NC' },
	{ label: 'North Dakota', value: 'ND' },
	{ label: 'Ohio', value: 'OH' },
	{ label: 'Oklahoma', value: 'OK' },
	{ label: 'Oregon', value: 'OR' },
	{ label: 'Pennsylvania', value: 'PA' },
	{ label: 'Rhode Island', value: 'RI' },
	{ label: 'South Carolina', value: 'SC' },
	{ label: 'South Dakota', value: 'SD' },
	{ label: 'Tennessee', value: 'TN' },
	{ label: 'Texas', value: 'TX' },
	{ label: 'Utah', value: 'UT' },
	{ label: 'Vermont', value: 'VT' },
	{ label: 'Virginia', value: 'VA' },
	{ label: 'Washington', value: 'WA' },
	{ label: 'West Virginia', value: 'WV' },
	{ label: 'Wisconsin', value: 'WI' },
	{ label: 'Wyoming', value: 'WY' },
];

