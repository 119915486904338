import React, {useContext} from 'react';
import { Button } from 'semantic-ui-react';
import { WizardContext } from "./Wizard";

const Complete = ({ orderID }) => {
	const sharedValues = useContext(WizardContext);
	const production = process.env.NODE_ENV === 'production';
	const url = production
		? 'https://office.peoplefourpeople.com/api/receipt?id=' + orderID
		: 'http://localhost:5002/api/receipt?id=' + orderID;

	return (
		<div className="purchase-complete">
			<i className="fas fa-check"></i>
			<h3>You're all set!</h3>
			<p>Thanks for signing up for a DNP Business Plan</p>
			{ sharedValues.plan !== 8 && <p>If you purchased one of our monthly plans, we encourge you to visit the backoffice where you can generate memberships for your employees & setup your store.</p> }
			<div>
				<Button
					content="View Receipt"
					size="huge"
					color="purple"
					type="button"
					as="a"
					href={url}
					target="_blank"
				/>
				{ sharedValues.plan !== 8 &&
				<Button
					content="Go to the Backoffice"
					size="huge"
					color="blue"
					type="button"
					as="a"
					target="_blank"
					href="https://office.peoplefourpeople.com"
				/>
				}
			</div>
		</div>
	);
};

export default Complete;